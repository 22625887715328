import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { formatNumber } from '../../../../../utils/formatacoes';

const ImpostosTable = ({ processoData }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      id: 'descricao',
      name: '',
      selector: (row) => row.descricao,
    },
    {
      id: 'valorCalculado',
      name: 'Calculado R$',
      right: true,
      selector: (row) => formatNumber(row.valorCalculado) ?? '-',
    },
    {
      id: 'valorDevido',
      name: 'Devido R$',
      right: true,
      selector: (row) => formatNumber(row.valorDevido) ?? '-',
    },
  ];

  useEffect(() => {
    if (!processoData) return;

    const linhas = [
      {
        descricao: 'II',
        valorCalculado: processoData.totais?.II?.valor_calculado_real ?? 0,
        valorDevido: processoData.totais?.II?.valor_devido_real ?? 0,
      },
      {
        descricao: 'IPI',
        valorCalculado: processoData.totais?.IPI?.valor_calculado_real ?? 0,
        valorDevido: processoData.totais?.IPI?.valor_devido_real ?? 0,
      },
      {
        descricao: 'PIS',
        valorCalculado: processoData.totais?.PIS?.valor_calculado_real ?? 0,
        valorDevido: processoData.totais?.PIS?.valor_devido_real ?? 0,
      },
      {
        descricao: 'COFINS',
        valorCalculado: processoData.totais?.COFINS?.valor_calculado_real ?? 0,
        valorDevido: processoData.totais?.COFINS?.valor_devido_real ?? 0,
      },
      {
        descricao: 'Antidumping',
        valorCalculado: processoData.totais?.ANTIDUMPING?.valor_calculado_real ?? 0,
        valorDevido: processoData.totais?.ANTIDUMPING?.valor_devido_real ?? 0,
      },
      {
        descricao: 'Taxa Siscomex',
        valorCalculado: 0,
        valorDevido: processoData.totais['Taxa Siscomex']?.valor_calculado_real,
      },
      {
        descricao: 'ICMS Isento/Exonerado',
        valorCalculado: processoData.totais?.ICMS?.valor_reduzido_real ?? 0,
        valorDevido: processoData.totais?.ICMS?.valor_reduzido_real ?? 0,
      },
      {
        descricao: 'ICMS Devido/Calculado',
        valorCalculado: processoData.totais?.ICMS?.valor_devido_real ?? 0,
        valorDevido: processoData.totais?.ICMS?.valor_devido_real ?? 0,
      },
      {
        descricao: 'ICMS Total',
        valorCalculado: processoData.totais?.ICMS?.valor_recolher_real ?? 0,
        valorDevido: processoData.totais?.ICMS?.valor_recolher_real ?? 0,
      },
    ];

    setRows(linhas);
  }, [processoData]);

  return (
    <Fragment>
      <DataTable dense columns={columns} data={rows} />
    </Fragment>
  );
};

export default ImpostosTable;
