import React from 'react';
import Select from 'react-select';
import { Input } from 'reactstrap';
import { H4, Span } from '../../../../Themes/Components/AbstractElements';
import { HelpCircle } from 'react-feather';

const Atributo = ({
  atributo,
  dominios,
  produtoData,
  updateDadosEditados,
  atributos,
  deveExibirAtributo,
  setAtributos,
  isEditMode,
}) => {
  const handleSelectChange = (id, selectedOption) => {
    setAtributos((prevAtributos) =>
      prevAtributos.map((atributo) => {
        if (atributo.id === id) {
          return { ...atributo, selectedOption: selectedOption };
        }
        return atributo;
      }),
    );
    updateDadosEditados(id, { selectedOption });
    handleCampoCondicionalChange(id, selectedOption);
  };

  const handleInputChange = (id, event) => {
    const { value } = event.target;
    setAtributos((prevAtributos) =>
      prevAtributos.map((atributo) => {
        if (atributo.id === id) {
          return { ...atributo, value };
        }
        return atributo;
      }),
    );
    updateDadosEditados(id, { value });

    const novosAtributos = atributos.map((atributo) => ({
      ...atributo,
      ignorar: atributo.ncm_atributo_dominio === 'true' || atributo.ncm_atributo_dominio === 'false',
      selectedOption: atributo.id === id ? { value } : atributo.selectedOption,
    }));

    setAtributos(novosAtributos);
  };

  const handleCampoCondicionalChange = (id, newValue) => {
    const atualizarAtributos = () => {
      const novosAtributos = atributos.map((atributo) => ({
        ...atributo,
        ignorar: atributo.ncm_atributo_dominio === 'true' || atributo.ncm_atributo_dominio === 'false',
        selectedOption: atributo.id === id ? newValue : atributo.selectedOption,
      }));

      const atributosExibir = novosAtributos.map((atributo) => ({
        ...atributo,
        ignorar: atributo.ncm_atributo_dominio === 'true' || atributo.ncm_atributo_dominio === 'false',
        exibir: deveExibirAtributo(atributo, atributo.selectedOption, id, novosAtributos),
        selectedOption: atributo.selectedOption,
      }));

      setAtributos(atributosExibir);
    };

    atualizarAtributos();
  };

  if (atributo.exibir != undefined && !atributo.exibir) return null;

  const renderAtributo = () => {
    switch (atributo.forma_preenchimento_atributo) {
      case 'LISTA_ESTATICA':
        return isEditMode ? (
          <Select
            value={
              dominios.find(
                (item) =>
                  item.value ===
                  (atributo.selectedOption?.value ||
                    produtoData.produto_atributos.find((item) => item.ncm_atributo_id === atributo.id)
                      ?.ncm_atributo_dominio_id),
              ) || null
            }
            options={dominios}
            onChange={(selectedOption) => handleSelectChange(atributo.id, selectedOption)}
            autoFocus
            placeholder='Selecione'
          />
        ) : (
          <span>
            {dominios.find(
              (item) =>
                item.value ===
                (atributo.selectedOption?.value ||
                  produtoData.produto_atributos.find((item) => item.ncm_atributo_id === atributo.id)
                    ?.ncm_atributo_dominio_id),
            )?.label || '-'}
          </span>
        );
      case 'BOOLEANO':
        return isEditMode ? (
          <Select
            value={
              [
                { value: '', label: 'Selecione' },
                { value: 'true', label: 'Sim' },
                { value: 'false', label: 'Não' },
              ].find(
                (option) =>
                  option.value ===
                  (atributo.selectedOption?.value ||
                    produtoData.produto_atributos.find((item) => item.ncm_atributo_id === atributo.id)
                      ?.ncm_atributo_dominio),
              ) || { value: '', label: 'Selecione' }
            }
            options={[
              { value: '', label: 'Selecione' },
              { value: 'true', label: 'Sim' },
              { value: 'false', label: 'Não' },
            ]}
            onChange={(selectedOption) => handleSelectChange(atributo.id, selectedOption)}
            autoFocus
            placeholder='Selecione'
          />
        ) : (
          <span>{atributo.selectedOption?.value === 'true' ? 'Sim' : 'Não'}</span>
        );
      case 'COMPOSTO':
        return (
          <div className='row col-sm-12'>
            {atributo.ncm_atributo_subatributos.map((subatributo) => {
              return (
                <Atributo
                  key={subatributo.id}
                  atributo={subatributo}
                  dominios={subatributo.ncm_atributo_dominios}
                  produtoData={produtoData}
                  handleSelectChange={handleSelectChange}
                  handleInputChange={handleInputChange}
                  isEditMode={isEditMode}
                  updateDadosEditados={updateDadosEditados}
                  atributos={atributos}
                  setAtributos={setAtributos}
                  deveExibirAtributo={deveExibirAtributo}
                />
              );
            })}
          </div>
        );
      default:
        return isEditMode ? (
          <Input
            className='form-control'
            type='textarea'
            value={atributo.selectedOption?.value || ''}
            onChange={(event) => handleInputChange(atributo.id, event)}
            required
          />
        ) : (
          <span>{atributo.selectedOption?.value || '-'}</span>
        );
    }
  };

  return (
    <div
      key={atributo.id}
      className={`${atributo.forma_preenchimento_atributo === 'COMPOSTO' ? 'col-sm-12 mt-4' : 'col-sm-3'} mb-2`}
    >
      <H4>
        <div
          style={{
            display: 'flex',
            gap: 4,
          }}
        >
          {atributo.nome_apresentacao}{' '}
          {atributo.obrigatorio ? (
            <span
              style={{
                color: '#FF0000',
              }}
            >
              *
            </span>
          ) : (
            ''
          )}
          {atributo.orientacao_preenchimento ? (
            <i
              className='icofont icofont-question-circle'
              style={{ fontSize: '1rem' }}
              title={atributo.orientacao_preenchimento}
            />
          ) : (
            ''
          )}
        </div>
      </H4>
      {renderAtributo()}
    </div>
  );
};

export default Atributo;
