import React from 'react';
import { formatDate, formatNumber } from '../../../../../utils/formatacoes';
import LpcosTable from './lpcosTable';
import DuesTable from './duesTable';
import { H3, H4, Span } from '../../../../../Themes/Components/AbstractElements';

const CoresCanaisDi = {
  'SEM CANAL': 'silver',
  VERDE: '#22BAA0',
  LARANJA: '#f2ca4c',
  VERMELHO: '#f25656',
};

const DadosAduaneiros = ({ embarque }) => {
  if (embarque?.model != 'Exportacao.Processos') {
    const embarqueProcesso = embarque?.embarque_processos[0]?.importacao_processo ?? {};

    return (
      <>
        {embarqueProcesso?.via_transporte?.codigo === '01' && (
          <>
            <H3>Dados da Carga</H3>
            <div className='row'>
              <div className='row mb-2'>
                <div className='col-sm-3'>
                  <H4>CE Mercante</H4>
                  <Span>{embarqueProcesso?.carga?.numero_ce ?? '-'}</Span>
                </div>
                <div className='col-sm-3'>
                  <H4>Situação da carga</H4>
                  <Span>
                    {embarque?.situacoes && embarqueProcesso?.carga?.status_id
                      ? embarque?.situacoes[embarqueProcesso?.carga?.status_id]
                      : '-'}
                  </Span>
                </div>
                <div className='col-sm-3'>
                  <H4>Qtde de itens</H4>
                  <Span>{embarqueProcesso?.carga?.quantidade_itens ?? '-'}</Span>
                </div>
                <div className='col-sm-3'>
                  <H4>Peso bruto</H4>
                  <Span>{embarqueProcesso?.carga ? formatNumber(embarqueProcesso.carga.peso_bruto, 3) : '-'}</Span>
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col-sm-3'>
                  <H4>Cubagem</H4>
                  <Span>{embarqueProcesso?.carga ? formatNumber(embarqueProcesso.carga.cubagem, 3) : '-'}</Span>
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col-sm-3'>
                  <H4>Moeda do Frete</H4>
                  <Span>
                    {embarqueProcesso?.carga?.moeda
                      ? `${embarqueProcesso.carga.moeda.codigo} - ${embarqueProcesso.carga.moeda.descricao}`
                      : '-'}
                  </Span>
                </div>
                <div className='col-sm-3'>
                  <H4>Total do Frete</H4>
                  <Span>
                    {embarqueProcesso?.carga ? formatNumber(embarqueProcesso.carga.frete_total_moeda, 3) : '-'}
                  </Span>
                </div>
                <div className='col-sm-3'>
                  <H4>Frete Básico</H4>
                  <Span>
                    {embarqueProcesso?.carga ? formatNumber(embarqueProcesso.carga.frete_basico_moeda, 3) : '-'}
                  </Span>
                </div>
                <div className='col-sm-3'>
                  <H4>Pagamento</H4>
                  <Span>
                    {console.log(embarqueProcesso?.carga?.frete_forma_pagamento)}
                    {{ P: 'PREPAID (PAGO)', C: 'COLLECT', '': '-' }[embarqueProcesso?.carga?.frete_forma_pagamento] ??
                      '-'}
                  </Span>
                </div>
              </div>
            </div>
          </>
        )}
        <div className='row m-t-20'>
          <H3>Dados Aduaneiros</H3>
          <div className='row mb-2'>
            <div className='col-sm-3'>
              <H4>Número da DI</H4>
              <Span>{embarqueProcesso?.diduimp_numero ?? '-'}</Span>
            </div>
            <div className='col-sm-3'>
              <H4>Data de Registro</H4>
              <Span>
                {embarqueProcesso?.diduimp_data_registro ? formatDate(embarqueProcesso.diduimp_data_registro) : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>Situação</H4>
              <Span>{embarqueProcesso?.processo_situacao?.descricao ?? '-'}</Span>
            </div>
            <div className='col-sm-3'>
              <H4>Canal</H4>
              <Span>
                {embarqueProcesso?.diduimp_canal ? (
                  <i
                    className='icofont icofont-info-circle'
                    style={{ color: CoresCanaisDi[embarqueProcesso.diduimp_canal] }}
                  ></i>
                ) : (
                  '-'
                )}
              </Span>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-sm-3'>
              <H4>Unidade RFB</H4>
              <Span>
                {embarqueProcesso?.despacho_unidade
                  ? `${embarqueProcesso.despacho_unidade.codigo} - ${embarqueProcesso.despacho_unidade.descricao}`
                  : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>Recinto Aduaneiro</H4>
              <Span>
                {embarqueProcesso?.armazem_recinto
                  ? `${embarqueProcesso.armazem_recinto.codigo} - ${embarqueProcesso.armazem_recinto.descricao}`
                  : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>Peso Líquido</H4>
              <Span>
                {embarqueProcesso?.total_peso_liquido ? formatNumber(embarqueProcesso.total_peso_liquido, 5) : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>Peso Bruto</H4>
              <Span>
                {embarqueProcesso?.total_peso_bruto ? formatNumber(embarqueProcesso.total_peso_bruto, 5) : '-'}
              </Span>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-sm-3'>
              <H4>VMCV</H4>
              <Span>
                {embarqueProcesso?.total_vmcv_moeda
                  ? formatNumber(embarqueProcesso.total_vmcv_moeda, 2, `${embarqueProcesso.moeda?.simbolo ?? ''} `)
                  : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>VMLE</H4>
              <Span>
                {embarqueProcesso?.total_vmle_moeda
                  ? formatNumber(embarqueProcesso.total_vmle_moeda, 2, `${embarqueProcesso.moeda?.simbolo ?? ''} `)
                  : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>Frete</H4>
              <Span>
                {embarqueProcesso?.total_frete_dolar
                  ? formatNumber(embarqueProcesso.total_frete_dolar, 2, 'USD ')
                  : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>Seguro</H4>
              <Span>
                {embarqueProcesso?.total_seguro_moeda
                  ? formatNumber(
                      embarqueProcesso.total_seguro_moeda,
                      2,
                      `${embarqueProcesso.seguro_moeda?.simbolo ?? ''} `,
                    )
                  : '-'}
              </Span>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-sm-3'>
              <H4>II</H4>
              <Span>
                {embarque?.model != 'Exportacao.Processos'
                  ? embarqueProcesso?.tributosTotal && 'II' in embarqueProcesso.tributosTotal
                    ? embarqueProcesso.tributosTotal['II']
                    : 0
                  : embarqueProcesso?.processo_itens && embarqueProcesso?.processo_itens[0]
                    ? formatNumber(embarqueProcesso.processo_itens[0]?.ii_vlr_devido, 2, 'R$ ')
                    : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>IPI</H4>
              <Span>
                {embarque?.model != 'Exportacao.Processos'
                  ? embarqueProcesso?.tributosTotal && 'IPI' in embarqueProcesso.tributosTotal
                    ? embarqueProcesso.tributosTotal['IPI']
                    : 0
                  : embarqueProcesso?.processo_itens && embarqueProcesso?.processo_itens[0]
                    ? formatNumber(embarqueProcesso.processo_itens[0]?.ipi_vlr_devido, 2, 'R$ ')
                    : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>PIS</H4>
              <Span>
                {embarque?.model != 'Exportacao.Processos'
                  ? embarqueProcesso?.tributosTotal && 'PIS' in embarqueProcesso.tributosTotal
                    ? embarqueProcesso.tributosTotal['PIS']
                    : 0
                  : embarqueProcesso?.processo_itens && embarqueProcesso?.processo_itens[0]
                    ? formatNumber(embarqueProcesso.processo_itens[0]?.pis_vlr_devido, 2, 'R$ ')
                    : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>COFINS</H4>
              <Span>
                {embarque?.model != 'Exportacao.Processos'
                  ? embarqueProcesso?.tributosTotal && 'COFINS' in embarqueProcesso.tributosTotal
                    ? embarqueProcesso.tributosTotal['COFINS']
                    : 0
                  : embarqueProcesso?.processo_itens && embarqueProcesso?.processo_itens[0]
                    ? formatNumber(embarqueProcesso.processo_itens[0]?.cofins_vlr_devido, 2, 'R$ ')
                    : '-'}
              </Span>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-sm-3'>
              <H4>ICMS</H4>
              <Span>
                {embarque?.model != 'Exportacao.Processos'
                  ? embarqueProcesso?.tributosTotal && 'ICMS' in embarqueProcesso.tributosTotal
                    ? embarqueProcesso.tributosTotal['ICMS']
                    : 0
                  : embarqueProcesso?.processo_itens && embarqueProcesso?.processo_itens[0]
                    ? formatNumber(embarqueProcesso.processo_itens[0]?.icms_valor_devido, 2, 'R$ ')
                    : '-'}
              </Span>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className='row'>
          <div className='col-sm-12'>
            <H3>LPCOs</H3>
            <LpcosTable situacoes={embarque?.situacoesLpco} lpcos={embarque?.embarque_lpcos} />
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12'>
            <H3>DU-Es</H3>
            <div className='table-responsive'>
              <DuesTable dues={embarque?.embarque_dues} />
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default DadosAduaneiros;
